

.container {
  display:flex;
  text-align: center;
}

body {
  overscroll-behavior-x: none;
  /* height: 100vh;
  width: 100vw; */
  height: 100%;
  width: 100%;
  background-color: black;
  margin: 0rem;
  color: white;
  overflow: hidden;
  font-family: "Lucida Console", "Courier New", monospace;
}

.test {
  overflow: hidden;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link{
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.links {
  display: flex;
  color: white;
  text-align: center;
  transform: translate(46%,200%);
  gap: 5vmin;
  margin-bottom: 100px;
}

.first {
  position: absolute;
  top: 10px;
  left: 50px;
  color: white;
}

.middle {
  position: absolute;
  bottom: 10px;
  left: 50px;
  color: white;
}

.last {
  position: absolute;
  bottom: 10px;
  right: 50px;
  color: white;
}

.date{
  /* opacity: 1.5; */
  color: rgb(46, 238, 46);
  text-align: right;
}

.title{
  color: rgb(46, 238, 46);

  text-align: center;
}

.info{
  opacity: .7;
  text-align: left;
}

.scroll{
  text-align: center;
  width: 12.5%;
  font-size: 4px;
  position: absolute;
  left: 6.25%;
  top: 23%;
  transform: translate(0%, 0%);
}

.scroll-box{
  border: 1.34px solid rgba(255,255,255,.3);
  position: absolute;
  width: 18.5%;
  left: 3.25%;
  height: 25.5%;
  top: 19%;
  transform: translate(0%, 0%);
}

.img-scroll-box{
  border: 1.34px solid rgba(255,255,255,.3);
  position: absolute;
  transform: translate(0%, 0%);
  width: 18.5%;
  height:5%;
  left: 3%;
  bottom: 8%;
}

.imgs-track{
  margin: 0 auto;
  display: flex;
  gap: .5vmin;
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.image{
  width: 48vmin;
  height: 64vmin;
  object-fit: cover;
  object-position: 100% 50%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.img{
  width:6vmin;
  height:8vmin;
  object-fit: cover;
  object-position: 100% 50%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.flex-container {
  position: relative;
  margin: 0% auto auto auto;
  max-width: 75%;
}

.flex-child {
  float: left;
  margin: 0px 20px 20px 0;
}  

.flex-child:first-child {
  margin-right: 20px;
}

.contact{
  /* width: 20%; */
  text-align: center;
  display: grid;
  gap: 1%;
  opacity: 0.3;

}

.zoom{
  color: white;
}