.portrait {
    border-radius: 25px;
    margin: auto auto;
    display: flex;
    width: 40vmin;
    height: 40vmin;
    object-fit: cover;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.image{
    width: 40%;
    float: left;
}
.intro{
    width: 60%;
    float: left;
}

.float{
    border: 3px solid #fff;
    padding: 20px;
}

.child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
}  

