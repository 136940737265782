.contact{
    /* width: 20%; */
    text-align: center;
    display: grid;
    gap: 1%;
    /* opacity: 0.3; */
}

.links{
    /* width: 100%; */
    text-align: center;
    color: white;
    transform: translate(46%,200%);
    display: flex;    
    gap: 5vmin;
    margin-bottom: 100px;
}

.about{
    /* justify-content: space-evenly; */
    text-align: center;
    width: 50%;
    margin: 0 auto;
    transform: translate(0, 0%);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

